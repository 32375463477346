<template>
  <div class="customerService">
    <div class="header relative">
      <video
          src="https://www.cdpee.org.cn/cs/upload1/2021-06-16/16238256687637.mp4"
          id="video"
          width="100%"
          height="100%"
          controls
          autoplay
          v-if="videos"
      ></video>
      <div class="videos" @click="openVideo" v-else>
        <img src="~/static/flexible/button.png" alt=""/>
      </div>
      <div class="close" v-if="videos" @click="close()">
        <img src="~/static/flexible/closery.png" alt=""/>
      </div>
    </div>
    <div class="customerService_content flex">
      <div class="left">
        <div class="text">
          <h2>什么是云客服？</h2>
          <p>
            只要你懂淘宝，乐意分享知识帮助需要帮助的人，只要你有时间，随时能上网解答淘宝客户的问题，欢迎你加入淘宝【云客服】。
            这群人在淘宝有个专业的身份：淘宝【云客服】。【云客服】们soho
            办公，不受地域、时间的局限，只要有时间，能上网，就能为淘宝客户提供在线咨询，让需要帮助的人们随时随地都能得到帮助。
          </p>
          <h2>工作内容与形式</h2>
          <p>
            不需要固定的办公室，也不需要朝九晚五的八小时。只要你有一颗服务淘宝会员的心，和一台可以上网的电脑，你就有希望成为云客服为淘宝的会员提供服务！
          </p>
          <h2>报名规则文字</h2>
          <p>报名周期：每月12日0点——次月10日24点。（根据节假日会有调整）<br/>
            报名截止后，关闭报名功能，由工作人员对报名学员进行资质审核。<br/>
            <br />
            1. 参加特云奇兵残疾人云客服灵活就业项目的残疾人、需持残疾人证，在规定时间内进行报名。<br />
            2. 不同报名周期可多次报名，但报名成功后在本周期内，不可重复报名。<br />
            3. 每月资质审核工作完成后，通过的学员会收到手机短信，请务必保持手机畅通。请收到短信的学员，按时参加培训和考试。
          </p>
        </div>
        <div class="buttons" v-if="isDisabled || isLogin.userType == 1">
          <el-button type="primary" @click="opens" v-if="isok"
          >立即报名
          </el-button
          >
          <el-button type="primary" disabled v-else>已报名</el-button>
        </div>
        <div class="buttons" v-else>
          <el-button type="primary" disabled>只允许残疾人报名</el-button>
        </div>
      </div>
      <div class="right text_c">
        <div class="info">
          <img src="~/static/flexible/icon.png"/>
          <p>我的云客服</p>
          <div class="status" v-if="isDisabled">
            <div class="colorEF" v-if="isApply == '未报名'">未报名</div>
            <div
                class="color00"
                style="cursor: pointer"
                v-else
                @click="dialogVisible1 = true"
            >
              查看报名详情<img
                src="~/static/flexible/you.png"
                class="inline_block img"
            />
            <div v-if="qqqun!=''">
              您的报名信息已审核通过，请加群（群号：{{qqqun}}）参加培训，考试 ，11号24点前未加入者，视为自动弃权。
            </div>
            </div>
          </div>
        </div>
        <div class="notice" v-if="noticeTitle">
          <h2>{{ noticeTitle }}</h2>
          <div>{{ noticeDetail }}</div>
        </div>
      </div>
    </div>
    <el-dialog
        v-if="dialogVisible"
        title="云客服报名"
        :visible.sync="dialogVisible"
        width="900px"
        :before-close="handleClose"
    >
      <div class="el-dialog_content">
        <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="130px"
            class="demo-form"
        >
          <el-form-item label="姓名" prop="userName" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请填写姓名"
                v-model="form.userName"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              label="身份证号"
              prop="idcard"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写身份证号"
                v-model="form.idcard"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="eduation" class="inline_block w400">
            <el-select v-model="form.eduation" placeholder="请选择学历" class="width100">
              <el-option
                  v-for="item in selectEdu"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              label="残疾人证号"
              prop="dcNumber"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写残疾人证号"
                v-model="form.dcNumber"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              label="残疾类别"
              prop="distype"
              class="inline_block w400"
          >
            <!--            form.distype,form.dislv-->
            <el-input
                type="text"
                placeholder="请填写残疾类别"
                :value="form.distype + form.dislv"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="淘宝账号" prop="taobaoaccount">
            <el-input
                type="text"
                placeholder="请填写淘宝账号"
                v-model="form.taobaoaccount"
                class="w270 inline_block"
            ></el-input>
            <div class="colorFF fonT14 inline_block">
              &nbsp;&nbsp;注：为本人注册，不是支付宝账号
            </div>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请填写手机号"
                v-model="form.mobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请填写电子邮箱"
                v-model="form.email"
            ></el-input>
          </el-form-item>
          <el-form-item label="淘宝信誉" prop="reputation">
            <el-input
                type="text"
                placeholder="请填写淘宝信誉"
                v-model="form.reputation"
                class="w270 inline_block"
            ></el-input>
            <div class="colorFF fonT14 inline_block">
              &nbsp;&nbsp;注：买家或卖家最好的等级，如卖家2星，买家1黄钻，则填写1黄钻
            </div>
          </el-form-item>
          <el-form-item
              class="inline_block w400"
              prop="taobaoaddress"
              label="淘宝网店地址"
          >
            <el-input
                type="text"
                placeholder="请填写淘宝网店地址"
                v-model="form.taobaoaddress"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="打字速度字/分钟"
              prop="typingspeed"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写打字速度"
                v-model="form.typingspeed"
            ></el-input>
          </el-form-item>
          <el-form-item prop="homeaddress" label="家庭住址">
            <el-input
                type="text"
                placeholder="请填写家庭详细住址"
                v-model="form.homeaddress"
            ></el-input>
          </el-form-item>
          <el-form-item
              class="inline_block w400"
              prop="isjoin"
              label="是否参加过云客服报名"
              label-width="200px"
          >
            <el-radio-group v-model="form.isjoin">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
              class="inline_block w400"
              prop="belong"
              label="所在地区或组织"
          >
            <el-input
                type="text"
                placeholder="所在地区或组织"
                v-model="form.belong"
            ></el-input>
          </el-form-item>
          <el-form-item class="w400" prop="disabiValid" label="残疾测评结果">
            <el-select
                v-model="form.disabiValid"
                clearable
                placeholder="残疾测评结果"
                disabled
            >
              <el-option
                  v-for="item in selectResult"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              class="w400"
              prop="evaluationResults"
              label="阿里测评结果"
          >
            <el-select
                v-model="form.evaluationResults"
                clearable
                placeholder="阿里测评结果"
                disabled
            >
              <el-option
                  v-for="item in selectResult"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class=" text_c paddT20" v-if="detail">
            <el-button type="primary" class="w170" @click="submitForm('form')" :loading="loadingIf"
            >{{btnText}}
            </el-button
            >
            <el-button
                class="border0D color00 w170 marR20"
                @click="resetForm('form')"
            >重置
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
        v-if="dialogVisible1"
        title="详情"
        :visible.sync="dialogVisible1"
        width="900px"
        :before-close="handleClose1"
    >
      <div class="el-dialog_content">
        <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="130px"
            class="demo-form"
        >
          <el-form-item label="姓名" prop="userName" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请填写姓名"
                v-model="form.userName"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              label="身份证号"
              prop="idcard"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写身份证号"
                v-model="form.idcard"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="eduation" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请输入学历"
                v-model="form.eduation"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              label="残疾人证号"
              prop="dcNumber"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写残疾人证号"
                v-model="form.dcNumber"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              label="残疾类别"
              prop="distype"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写残疾类别"
                :value="form.distype + form.dislv"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="淘宝账号" prop="taobaoaccount">
            <el-input
                type="text"
                placeholder="请填写淘宝账号"
                v-model="form.taobaoaccount"
                class="w270 inline_block"
                disabled
            ></el-input>
            <div class="colorFF fonT14 inline_block">
              &nbsp;&nbsp;注：为本人注册，不是支付宝账号
            </div>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请填写手机号"
                v-model="form.mobile"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email" class="inline_block w400">
            <el-input
                type="text"
                placeholder="请填写电子邮箱"
                v-model="form.email"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="淘宝信誉" prop="reputation">
            <el-input
                type="text"
                placeholder="请填写淘宝信誉"
                v-model="form.reputation"
                class="w270 inline_block"
                disabled
            ></el-input>
            <div class="colorFF fonT14 inline_block">
              &nbsp;&nbsp;注：买家或卖家最好的等级，如卖家2星，买家1黄钻，则填写1黄钻
            </div>
          </el-form-item>
          <el-form-item
              class="inline_block w400"
              prop="taobaoaddress"
              label="淘宝网店地址"
          >
            <el-input
                type="text"
                placeholder="请填写淘宝网店地址"
                v-model="form.taobaoaddress"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              label="打字速度字/分钟"
              prop="typingspeed"
              class="inline_block w400"
          >
            <el-input
                type="text"
                placeholder="请填写打字速度"
                v-model="form.typingspeed"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item prop="homeaddress" label="家庭住址">
            <el-input
                type="text"
                placeholder="请填写家庭详细住址"
                v-model="form.homeaddress"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              class="inline_block w400"
              prop="isjoin"
              label="是否参加过云客服报名"
              label-width="200px"
          >
            <el-radio-group v-model="form.isjoin" disabled>
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
              class="inline_block w400"
              prop="belong"
              label="所在地区或组织"
          >
            <el-input
                type="text"
                placeholder="所在地区或组织"
                v-model="form.belong"
                disabled
            ></el-input>
          </el-form-item>
          <el-form-item
              class="w400"
              prop="disabiValid"
              label="残疾测评结果"
              v-if="false"
          >
            <el-select
                v-model="form.disabiValid"
                clearable
                placeholder="残疾测评结果"
                disabled
            >
              <el-option
                  v-for="item in selectResult"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              class="w400"
              prop="evaluationResults"
              label="阿里测评结果"
              v-if="false"
          >
            <el-select
                v-model="form.evaluationResults"
                clearable
                placeholder="阿里测评结果"
                disabled
            >
              <el-option
                  v-for="item in selectResult"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <div class="tip success"  v-if="form.disabiValid == 1">-->
          <!--            残疾状况验证通过-->
          <!--          </div>-->
          <!--          <div class="tip error"  v-if="form.disabiValid != 2">-->
          <!--            残疾状况验证不通过-->
          <!--          </div>-->
        </el-form>
      </div>
    </el-dialog>
    <login-prompt
        :goShow="isHide"
        @close="handleClose"
        title="登录"
        text="登录之后才可以报名"
    ></login-prompt>
  </div>
</template>
<script>
import loginPrompt from "@/components/public/loginPrompt";
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";

export default {
  name: "flexible",
  components: {
    loginPrompt,
    MulSelect,
    ThreeLink,
  },
  data() {
    return {
      btnText:'提交',
      loadingIf:false,
      loginstatus: null,
      videos: false,
      isApply: "未报名",
      isLogin:'',
      qqqun:"",
      applyId: "",
      isok: false,
      isDisabled: false,
      dialogVisible: false,
      dialogVisible1: false,
      detail: true,
      title: "云客服报名",
      disTypeList: [],
      DisableLvs: [],
      noticeTitle: "",
      noticeDetail: "",
      form: {
        batch: "",
        userName: "",
        sex: "",
        idcard: "",
        hasdc: "是",
        distype: "",
        dislv: "",
        belong: "",
        dcNumber: "",
        disableReason: "",
        eduation: "",
        graduationtime: "",
        schoolname: "",
        studentid: "",
        taobaoaccount: "",
        alipayaccount: "",
        mobile: "",
        email: "",
        qq: "",
        hobby: "",
        occupation: "",
        typingspeed: "",
        reputation: "",
        taobaoaddress: "",
        isjoin: "",
        homeaddress: "",
        fwjg: "",
        cdpf: "",
        evaluationResults: "-1",
        disabiValid: "-1",
      },
      dialogVisible3: true,
      selectEdu: [
        {
          value: '小学及以下',
          label: '小学及以下'
        }, {
          value: '初中',
          label: '初中'
        }, {
          value: '中专/高中',
          label: '中专/高中'
        }, {
          value: '专科',
          label: '专科'
        }, {
          value: '本科',
          label: '本科'
        },
        {
          value: '硕士及以上',
          label: '硕士及以上'
        }],
      selectResult: [
        {code: "-1", value: "未验证"},
        {code: "0", value: "未通过"},
        {code: "1", value: "通过"},
      ],
      rules: {
        userName: [{required: true, message: "请输入姓名", trigger: "blur"}],
        sex: [{required: true, message: "请输入性别", trigger: "blur"}],
        dcNumber: [
          {required: true, message: "请输入残疾人证件号", trigger: "blur"},
        ],
        disableReason: [
          {required: true, message: "请填写致残原因", trigger: "blur"},
        ],
        eduation: [{required: true, message: "请输入学历", trigger: "blur"}],
        schoolname: [
          {required: true, message: "请输入学校名称", trigger: "blur"},
        ],
        studentid: [
          {required: true, message: "请输入在校学号", trigger: "blur"},
        ],
        mobile: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        qq: [{required: true, message: "请输入qq号", trigger: "blur"}],
        taobao: [
          {required: true, message: "请输入您的淘宝正好", trigger: "blur"},
        ],
        taobaoaccount: [
          {required: true, message: "请输入您的淘宝会员名", trigger: "blur"},
        ],
        alipayaccount: [
          {required: true, message: "请输入您的支付宝账号", trigger: "blur"},
        ],
        email: [
          {
            required: true,
            message: "请输入您有效的电子邮箱",
            trigger: "blur",
          },
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: "电子邮箱格式不对",
            trigger: "blur",
          },
        ],
        hobby: [{required: true, message: "请填写兴趣爱好", trigger: "blur"}],
        typingspeed: [
          {required: true, message: "请填写打字速度", trigger: "blur"},
        ],
        reputation: [
          {required: true, message: "请填写淘宝信誉", trigger: "blur"},
        ],
        isjoin: [
          {
            required: true,
            message: "请选择是否参加过与客服报名",
            trigger: "change",
          },
        ],
        distype: [
          {required: true, message: "请选择残疾类别", trigger: "change"},
        ],
        dislv: [
          {required: true, message: "请选择残疾等级", trigger: "change"},
        ],
        belong: [
          {required: true, message: "请填写所在地区或组织", trigger: "blur"},
        ],
      },
      isHide: false,
    };
  },
  watch: {
    isApply: {
      handler: function (val) {
        console.log(val);
        if (val === "已报名") {
          this.isok = false;
        } else if (val === "未报名") {
          this.isok = true;
        } else if (val === "再次报名") {
          this.isok = true;
        }
      },
    },
  },
  methods: {
    submitForm(formName) {
      let that = this;
      this.btnText = '提交中';
      this.loadingIf = true;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
              .post("/api/app-jycy-activity/addYunkefuApply", that.form)
              .then(function (result) {
                if (result.data.success) {
                  that.$message.success("报名成功");
                  that.dialogVisible = false;
                } else {
                  that.$message.error(result.data.msg);
                }
                that.getApply(that.form.batch);
              });
        } else {
          this.$message.error("数据未填写");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getInfo() {
      let that = this;
      this.$axios
          .get("/api/app-jycy-disabled/disbled/getMyDisAbledInfo")
          .then((result) => {
            if (result.data.data) {
              let data = result.data.data;
              console.log(data);
              that.form.userName = data.userName;
              that.form.idcard = data.idNumber;
              that.form.dcNumber = data.dcNumber;
              that.form.distype = data.disabledType[0].disabledType;
              that.form.dislv = data.disabledType[0].disabledLevel;
            }
          });
    },
    getNotice() {
      let that = this;
      this.$axios.get("/api/app-jycy-activity/getYunNotice").then((result) => {
        if (result.data.success) {
          let data = result.data.data;
          if (data) {
            that.noticeTitle = data.title;
            that.noticeDetail = data.notice;
          }
        }
      });
    },
    getApply(time) {
      let _this = this;
      this.$axios
          .get("/api/app-jycy-activity/getYunkefuApply", {
            batch: time,
          })
          .then((result) => {
            if (result.data.success) {
              //_this.isApply = result.data.msg;
              console.log(result.data.msg);
              var msgList=result.data.msg.split(",");
               _this.isApply=msgList[0];
              if(msgList.length>1)
                  _this.qqqun=msgList[1];
              if (localStorage.getItem("userType")) {
                let login = JSON.parse(localStorage.getItem("userType"));
                this.isLogin = login;
                if (login.userType == 1) {
                  if (_this.isApply == "已报名") {
                    _this.form = result.data.data;
                    _this.isok = false;
                    _this.isDisabled = true;
                  } else if (_this.isApply == "未报名") {
                    _this.isDisabled = true;
                    _this.isok = true;
                  } else {
                    _this.isDisabled = false;
                  }
                } else {
                  this.isDisabled = false;
                }
              }
            } else {
              this.isHide = false;
            }
          });
    },
    //选择
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    video() {
      let media = document.getElementById("video");
    },
    opens() {
      this.dialogVisible = true;
      this.detail = true;
      this.getInfo();
    },
    handleClose() {
      this.dialogVisible = false;
      this.isHide = false;
    },
    handleClose1() {
      this.dialogVisible1 = false;
    },
    openVideo() {
      this.videos = true;
    },
    edit() {
      this.dialogVisible = false;
    },
    close() {
      this.videos = false;
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    //  获取批字号
    getYear() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getUTCMonth() + 1;
      this.form.batch = '' ;
      // year.toString() + month
      this.getApply(this.form.batch);
    },
  },
  created() {
    this.video();
    this.getInfo();
    //获取批字号
    this.getYear();
    this.getNotice();
    this.$mobile('https://lzmz.cdpee.org.cn/#/pages/flexible/flexible')
  },
};
</script>

<style scoped lang="less">
.customerService {
  .header {
    width: 100%;
    height: 340px;
    background: url("~static/flexible/banner.png") center top no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;

    .videos {
      position: absolute;
      left: 40%;
      bottom: 25px;
      cursor: pointer;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1000;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .customerService_content {
    width: 1200px;
    margin: 0 auto;

    div.left {
      flex: 8;
      background: white;
      padding: 20px 0;

      div.text {
        h2 {
          font-size: 20px;
          padding: 20px;
        }

        p {
          color: #666666;
          font-size: 14px;
          padding: 20px;
        }
      }

      div.buttons {
        text-align: center;
        padding: 40px 0;

        button {
          width: 300px;
        }
      }
    }

    div.right {
      flex: 2;
      margin-left: 20px;

      .info {
        padding-top: 30px;
        min-height: 100px;
        max-height: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          font-size: 16px;
        }

        .status {
          margin: 20px 0;
          font-size: 16px;
          div{

            color:black!important;
          }
          
        }

        background: white;
      }

      .notice {
        margin-top: 10px;
        background: white;
        padding: 20px;
        text-align: left;

        h2 {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.dialogVisible3 {
  .dialog_content {
    h1 {
      color: #f53c3d;
      text-align: center;
      padding: 20px;
    }

    .text {
      text-indent: 2em;
      line-height: 2em;
    }
  }

  .dialog_footer {
    text-align: center;

    button {
      width: 200px;
    }
  }
}

.tip {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.success {
  color: #00924b;
}

.error {
  color: #ff0000;
}
</style>
