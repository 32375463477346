<template>
  <el-select
      :size="size"
      v-model="value"
      :placeholder="placeholder"
      @change="handleChange"
      :filterable="filterable"
      clearable
  >
    <el-option v-for="item in options" :key="item.index" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "MulSelect",
  props: ["code", "defaultValue", "size", "placeholder", "unlimitedEdu","filterable"],
  data() {
    return {
      options: [],
      value: ""
    };
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        // this.options.forEach((n, i) => {
        //   if(n.value.indexOf(val) !== -1){
              this.value = val;
        //   }
        // })
      }
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("setMValue", value);
    },
    initDictData() {
      //根据字典Dict, 初始化字典数组
      let code = this.code;
      this.$axios
          .get("/api/app-jycy-sysdict/commonDictValue?dictCode=" + code)
          .then(res => {
            this.options = res.data.data;
            if (this.unlimitedEdu) {
              this.options = this.options.slice(1);
            }
          });
    }
  },
  created() {
    this.initDictData();
    // this.options.forEach((n, i) => {
    //   if(n.value.indexOf(this.defaultValue) !== -1){
        this.value = this.defaultValue;
    //   }
    // })
  }
};
</script>
